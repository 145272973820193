import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import BurnToken from './BurnToken';

function App() {


  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/convert-token' element={<BurnToken />} />
      </Routes>
    </>
  );
}

export default App;
