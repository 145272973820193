import { BURN_PROTOCOL_CONTRACT_BETA } from "../constants";
import env from "../constants/env";

export const lycanChainBeta = {
  id: 4321,
  name: "Lycan BETA",
  network: "Lycan BETA",
  nativeCurrency: {
    decimals: 18,
    name: "Beta Lycan",
    symbol: "bLYC",
  },
  rpcUrls: {
    public: { http: ["https://rpc-beta.lycanchain.com"] },
    default: { http: ["https://rpc-beta.lycanchain.com"] },
  },
  blockExplorers: {
    default: "https://explorer-beta.lycanchain.com",
  },
  testnet: true,
  contracts: {
    BURN_PROTOCOL_CONTRACT: BURN_PROTOCOL_CONTRACT_BETA,
    ERC20_CONTRACT_ADDRESS: "0x1F5a52A211C3471CC29aB377610F4c31e096CD78",
  },
};

export const lycanChain = {
  id: 721,
  name: "Lycan",
  network: "Lycan",
  nativeCurrency: {
    decimals: 18,
    name: "Lycan",
    symbol: "LYC",
  },
  rpcUrls: {
    public: { http: ["https://rpc.lycanchain.com"] },
    default: { http: ["https://rpc.lycanchain.com"] },
  },
  blockExplorers: {
    default: "https://explorer.lycanchain.com",
  },
  testnet: false,
  contracts: {
    stakingaura: "0x3A1A1a46d2Ae1F50edEe56fEfE7385F9c71960fA",
    validatoraura: "0x094096D94a0639491e5E91E5039ff24AF5e2D783",
  },
};

const chain = env?.VITE_IS_TESTNET ? lycanChainBeta : lycanChain;
export default chain;
