import { Web3 } from "web3";

const web3 = new Web3();

export const shortenAddress = (address) => {
  if (address && typeof address === "string") {
    return `${address?.substring(0, 6)}...${address?.substring(
      address?.length - 6
    )}`;
  } else {
    return "-";
  }
};

export function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("copied : ", text);
    })
    .catch((err) => {});
};

export const openExternalUrl = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const openExplorerAddress = (address) => {
  window.open(
    `https://explorer.lycanchain.com/address/${address}`,
    "_blank",
    "noopener,noreferrer"
  );
};

export const openExplorerTransaction = (txHash) => {
  window.open(
    `https://explorer.lycanchain.com/tx/${txHash}`,
    "_blank",
    "noopener,noreferrer"
  );
};

export function getPhase(epoch) {
  const prevEpoch = epoch - 1;
  const phase = prevEpoch % 4 === 0 ? 4 : prevEpoch % 4;
  let phaseName;

  switch (phase) {
    case 1:
      phaseName = "newmoon";
      break;
    case 2:
      phaseName = "firstquater";
      break;
    case 3:
      phaseName = "fullmoon";
      break;
    case 0: // for epochs where the remainder is 0, it's the 4th phase
      phaseName = "lastquater";
      break;
    default:
      phaseName = "newmoon"; // Fallback in case of an unexpected value
  }
  return phaseName;
}

export function hexToDecimal(hexString) {
  // Ensure the string is properly formatted by removing leading "0x" if present
  if (hexString.startsWith("0x")) {
    hexString = hexString.slice(2);
  }

  // Convert the hexadecimal string to a decimal number
  return parseInt(hexString, 16);
}

export function isStringInArray(stringToFind, arrayList) {
  return arrayList.includes(stringToFind);
}

export function formatTime(seconds) {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const pad = (num) => String(num).padStart(2, "0");

  return `${pad(days)} D : ${pad(hours)} H : ${pad(minutes)} M : ${pad(
    seconds
  )} S`;
}

export function getTenPercent(value) {
  return value * 0.1;
}

export function deductTenPercent(value) {
  return value - value * 0.1;
}

export function toWei(value) {
  return web3.utils.toWei(value.toString(), "ether");
}

export function toMwei(value) {
  return web3.utils.toWei(value.toString(), "mwei");
}
