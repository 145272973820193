import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { lycanChainBeta } from "./chain";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Your WalletConnect Cloud project ID
const projectId = "05bf1a3bdce78ac897ccc68fafd1c7af";

const metadata = {
  name: "BurnStrike",
  description: "BurnStrike",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [lycanChainBeta];

const myWallets = [
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  "622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
  "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
];

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  myWallets,

  //   ...wagmiOptions // Optional - Override createConfig parameters
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  includeWalletIds: myWallets,

  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
});

const WalletConnectProvider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default WalletConnectProvider;
