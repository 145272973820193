import { createPublicClient, http, getContract, erc20Abi } from "viem";
import chain, { lycanChain, lycanChainBeta } from "../_providers/chain";
import { useWalletClient } from "wagmi";
import { useMemo } from "react";

import ABI from "../contract/BurnProtocolv2_ABI.json";

function usePublicClient() {
  return createPublicClient({
    // chain: lycanChain,
    chain: lycanChainBeta,
    transport: http(),
  });
}

function useContract() {
  const { data: signer } = useWalletClient();
  const publicClient = usePublicClient();

  return useMemo(() => {
    if (!lycanChainBeta.contracts.BURN_PROTOCOL_CONTRACT || !ABI) return null;
    try {
      return getContract({
        address: lycanChainBeta.contracts.BURN_PROTOCOL_CONTRACT,
        abi: ABI,
        client: { public: publicClient, wallet: signer },
      });
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [signer]);
}

function useDynamicTokenContract(contractAddress) {
  const { data: signer } = useWalletClient();
  const publicClient = usePublicClient();

  return useMemo(() => {
    if (!contractAddress || !erc20Abi) return null;
    try {
      return getContract({
        address: contractAddress,
        abi: erc20Abi,
        client: { public: publicClient, wallet: signer },
      });
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [signer]);
}

export { useContract, useDynamicTokenContract };
