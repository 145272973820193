//TEST CONTRACT 0x5799E8fc8fcd6f07a1E282F21C4e8432AaC0ee1f
// export const CONTRACT_ADDRESS = '0x2543a1BbE1dcb3192Dc00ed046f3d1146f805915';

//BETA CONTRACT ADDRESS

// export const BURN_PROTOCOL_CONTRACT_BETA =
//   "0x44555C6857BeB4904315589c2363a3041f68A76c";

// export const BURN_PROTOCOL_CONTRACT_BETA =
//   "0x6863Dc1F9eE49F58d460490f7053f8Ab79865Bb0";

export const BURN_PROTOCOL_CONTRACT_BETA =
  "0xCA23633Aa252B0e7e40cD5D786836398d45AABd5";

export const ERC20_CONTRACT_ADDRESS_BETA =
  "0x1F5a52A211C3471CC29aB377610F4c31e096CD78";

// TEST : 3012 , BETA : '4321'
export const CHAIN_ID = 4321;

export const CHAIN_NAME = "LYCAN-BETA";

export const SYMBOL = "BLYC";
export const DECIMALS = 18;

export const BST_PRICE = 0.5;

export const RPC = "https://rpc-beta.lycanchain.com";

export const ROOT_URL = "http://18.117.41.254:8545";
export const CONFIRMATIONS = 6;
export const FIX_DECIMALS = 6;

export const EXPLORER_SUBDOMAIN = "explorer-beta";

export const RPC_URLS_DEFAULT = [
  "https://rpc.lycanchain.com",
  "https://us-east.lycanchain.com",
  "https://us-west.lycanchain.com",
  "https://eu-north.lycanchain.com",
  "https://eu-west.lycanchain.com",
  "https://asia-southeast.lycanchain.com",
];

export const RPC_URLS_WEBSOCKET = [
  "wss://rpc.lycanchain.com",
  "wss://us-east.lycanchain.com",
  "wss://us-west.lycanchain.com",
  "wss://eu-north.lycanchain.com",
  "wss://eu-west.lycanchain.com",
  "wss://asia-southeast.lycanchain.com",
];

export const BLOCK_EXPLORER = {
  name: "Lycan Explorer",
  url: "https://explorer.lycanchain.com",
};

export const NavbarItems = [];

export const assetsList = [
  {
    id: 1,
    name: "test asset 1",
    apy: "0.4",
    collateral: true,
  },
  {
    id: 2,
    name: "test asset 2",
    apy: "0.4",
    collateral: true,
  },
];

export const stats = [
  { id: 1, name: "Transactions every 24 hours", value: "44 million" },
  { id: 2, name: "Assets under holding", value: "$119 trillion" },
  { id: 3, name: "New users annually", value: "46,000" },
];

export const benefits = [
  {
    title: "Earn Rewards",
    desc: "Every burn helps reduce the token supply while earning you token-based rewards",
    logo: "",
  },
  {
    title: "Real-Time Competition",
    desc: "Compete with other players to burn the most tokens and climb the leaderboards.",
    logo: "",
  },
  {
    title: "Boost Your Game",
    desc: "Special events and booster packs offer opportunities to increase your rewards.",
    logo: "",
  },
  {
    title: "Transparent & Fair",
    desc: "Powered by blockchain, every action is transparent, secure and verifiable.",
    logo: "",
  },
];

export const tokenList = [
  {
    tokenName: "LycanChain",
    tokenSymbol: "LYC",
    icon: 'assets/images/lyc.svg',
    tokenAddress: "0x0000000000000000000000000000000000000001",
    chainId: "4321",
    decimal: "18",
  },
  // {
  //   tokenName: "xDC",
  //   tokenSymbol: "xDC",
  // icon: 'assets/images/lyc.svg',
  //   tokenAddress: "0x1F5a52A211C3471CC29aB377610F4c31e096CD78",
  //   chainId: "4321",
  //   decimal: "18",
  // },
  {
    tokenName: "HOME TOKEN",
    tokenSymbol: "HTK",
    icon: 'assets/images/burn.svg',
    tokenAddress: "0xE8F64b9C6D672B43F588a281EF27687c145dA76F",
    chainId: "4321",
    decimal: "18",
  },
];
